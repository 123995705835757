import React from 'react';
import { Link } from 'react-scroll';

const HomeInfo = () => {
    return (
        <section id="information" className="block">
            <div className="wrapper">
                <div className="row no-gutters information-items">
                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <div className="information information-color-1">
                            <div className="information-icon">
                                <i className="pnp-icon-call-2"></i>
                            </div>

                            <h4 className="information-t-head">Umów wizytę</h4>

                            <div className="information-btn">
                                <i className="fas fa-phone-alt"></i>
                                <a title="Zadzwoń do nas" className="btn btn-lg btn-link btn-line p-0 border-0 min-w-auto link-no-space text-uppercase" href="tel:+48509669667">
                                        509 669 667
                                </a>
                            </div>

                            <div className="information-btn">
                                <i className="far fa-envelope"></i>
                                <a title="Napisz do nas" className="btn btn-sm btn-link btn-line p-0 border-0 min-w-auto link-no-space" href="mailto:kontakt@przychodnianapanskiej.pl">
                                    kontakt@przychodnianapanskiej.pl
                                </a>
                            </div>

                        </div>
                    </div>

                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <div className="information information-color-2 information-icon-margin">
                            <div className="information-icon">
                                <i className="pnp-icon-dental-care"></i>
                            </div>

                            <h4 className="information-t-head">Lokalizacja</h4>

                            <div className="information-description">
                                <p>Przychodnia na Pańskiej</p>
                                <p>ul. Pańska 19</p>
                                <p>Rzeszów</p>
                            </div>

                            <div className="information-btn">
                                <Link to="google-maps" title="Znajdź nas na mapie" className="btn btn-sm btn-link btn-line p-0 border-0 min-w-auto link-no-space text-uppercase scroll-to-id" href="#google-maps">Zobacz na mapie</Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <div className="information information-color-3">
                            <div className="information-icon">
                                <i className="pnp-icon-calendar"></i>
                            </div>

                            <h4 className="information-t-head">Godziny Przyjęć</h4>

                            <div className="information-description">
                                <p><span className="d-flex justify-content-between flex-wrap"><span>Poniedziałek:</span><span className="text-right">9:00 - 19:00</span></span></p>
                                <p><span className="d-flex justify-content-between flex-wrap"><span>Środa:</span><span className="text-right">16:00 - 19:00</span></span></p>
                                <p><span className="d-flex justify-content-between flex-wrap"><span>Piątek:</span><span className="text-right">9:00 - 19:00</span></span></p>
                                <p>Leczenie w innych terminach jest możliwe, po umówieniu wizyty z lekarzem.</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HomeInfo;
